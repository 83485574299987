import { defineStore } from "pinia";
import { getRegion } from "@/apis/commen";
import { inspectionList } from "@/apis/database";

export const useStore = defineStore("database", {
  state: () => {
    return {
      region: [],
      inspectionList: [],
      pageIndex: 1,
      pageSize: 20,
      total: 100,
      pages: 100,
      form: {
        inspReg: "",
        notificationDateStart: "",
        notificationDateEnd: "",
        testResult: "",
        regProvince: "",
        firstCateId: "",
        secondCateId: "",
        thirdCateId: "",
        fourCateId: "",
        productName: "",
      },
    };
  },
  actions: {
    async getRegion(code = 0) {
      var res = await getRegion(code);
      this.region = res;
    },

    async getInspectionList(value, cancel) {
      var res = await inspectionList(
        {
          currPage: 1,
          orderItems: [{ column: "notificationDate", asc: false }],
          pageSize: 20,
          queryItems: [
            {
              column: "inspReg",
              // exp: "like",
              value: value.inspReg,
            },
            {
              column: "notificationDateStart",
              exp: "ge",
              value: value.notificationDateStart,
            },
            {
              column: "notificationDateEnd",
              exp: "le",
              value: value.notificationDateEnd,
            },
            { column: "productName", exp: "like", value: value.productName },
            { column: "testResult", exp: "eq", value: value.testResult },
            {
              column: "regProvince",
              exp: "eq",
              value: value.regProvince,
            },
            {
              column: "firstCateId",
              exp: "eq",
              value: value.firstCateId,
            },
            {
              column: "secondCateId",
              exp: "eq",
              value: value.secondCateId,
            },
            {
              column: "thirdCateId",
              exp: "eq",
              value: value.thirdCateId,
            },
            { column: "fourCateId", exp: "eq", value: value.fourCateId },
          ],
        },
        {
          cancelToken: cancel.token,
        }
      );
      this.inspectionList = res.records;
      this.pageIndex = res.current;
      this.pageSize = res.size;
      this.pages = res.pages;
      this.total = res.total;
    },
  },
  cancelOptions: {
    white: ["getInspectionList"],
  },
});
