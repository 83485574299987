import request from "@/utils/request";

export function inspectionList(data, config = {}) {
  return request({
    url: `/website/inspectionList`,
    method: "POST",
    data,
    ...config,
  });
}
