import dayjs from "dayjs";
export const createDate = () => {
  let endYear = dayjs().year();
  let startYear = 2011;

  let result = Array.from({
    length: endYear + 1 - startYear,
  });

  result = result.map((item, i) => {
    return {
      label: endYear - i,
      value: [
        dayjs()
          .year(endYear - i)
          .startOf("year")
          .valueOf(),
        dayjs()
          .year(endYear - i)
          .endOf("year")
          .valueOf(),
      ],
    };
  });

  return result;
};
