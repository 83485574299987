import { transfromObject } from "@/utils/util";
import { ref, watch } from "vue";

export const useTransfromObject = (format, form, init = []) => {
  let value = ref(init);

  const update = (val) => {
    Object.assign(form, transfromObject(val, format));
  };

  watch(value, update);

  return {
    update,
    form,
    value,
  };
};
